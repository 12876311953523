import * as React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import {formatDate} from "../helpers/date"
import Layout from "../components/layout"
import HeaderScriptsComponent from "../components/headerScripts";

export const query = graphql`
    query ($uid: String) {
        entry: craftPressReleasesDefaultEntry(uid: {eq: $uid}) {
            seomatic {
                ... on Craft_SeomaticType {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                    metaSiteVarsContainer
                }
            }
            title
            postDate
            pressReleaseBody
        }
    }
`

const PressReleaseTemplate = ({data}) => {
    return (
        <Layout seomatic={data.entry.seomatic}>
            <section className="page-hero-section section-regular bg-white page-hero-section-bg pb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="hero-section-content text-center mobile-py-20">
                                <h1 className="f-midium mobile-f-40">{data.entry.title}</h1>
                                <p className="mb-0 text-uppercase h1 border-0">{formatDate(data.entry.postDate)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-hero-section-bg page-hero-section-bg-scale">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-left">
                            <div className="box-with-border bg-white">
                                <div className="et_pb_text_inner">
                                    {parse(data.entry.pressReleaseBody)}
                                    <hr/>
                                    <h6>CORDIANCE LLC</h6>
                                    <p><em>Cordiance builds advanced technology solutions which are changing the way that tax engines like ONESOURCE, Vertex, and others are implemented. We deliver the fastest, most advanced, and most innovative tax engine implementations in the industry. Our seasoned management team are tax and technology industry veterans known for pioneering and innovation. Cordiance is privately-owned.</em></p>
                                    <p><em>For more information, visit: <a href="https://www.cordiance.com/">www.cordiance.com</a>, call Jared Rowe at <a href="tel:15129863033">(512) 986-3033</a>, or email <a href="mailto:jared@cordiance.com">jared@cordiance.com</a>.</em></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-90 pt-60">
                        <div className="col-md-12 text-center">
                            <a className="btn btn-primary" href="/news/press">Return to Press & Events</a>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PressReleaseTemplate

export const Head = ({data}) => (
    <HeaderScriptsComponent data={data} />
)